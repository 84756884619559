import { Link, useRouteMatch } from "react-router-dom";
import { FC } from "react";
import { Layout } from "antd";

import { getRawRoute, getRoute, RoutePathName } from "../routes";
import Logo from "./Logo";
import UserDropdown from "./UserDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import ProductName from "./ProductName";
import HeaderWareHouseInfos from "./HeaderWarehouseInfos";
import HeaderStoreInfos from "./HeaderStoreInfos";

import "../assets/styles/Header.less";

const Header: FC = () => {
    const isChooseHubPage = useRouteMatch(getRawRoute(RoutePathName.chooseHub));
    return (
        <Layout.Header id="main-header">
            <nav className="flex">
                <Link
                    to={getRoute(RoutePathName.home)}
                    title="Accueil"
                    className="ml-8 flex items-center gap-16 text-white hover:text-white"
                >
                    <Logo />
                    <ProductName />
                </Link>
                <HeaderWareHouseInfos />
                <HeaderStoreInfos />
            </nav>
            <div className="flex h-full items-center space-x-16">
                {!isChooseHubPage && <NotificationsDropdown />}
                <UserDropdown />
            </div>
        </Layout.Header>
    );
};

export default Header;
