/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.167 2.917c0-.645.522-1.167 1.166-1.167h23.334c.644 0 1.166.522 1.166 1.167V24.6q0 .125-.025.242.026.117.026.241c0 .645-.523 1.167-1.167 1.167H7.593a1.167 1.167 0 1 1 0-2.333H24.5V11.083h-21V17.6a1.167 1.167 0 0 1-2.333 0zM3.5 8.75V4.083h21V8.75zm1.167-2.335c0-.645.522-1.167 1.166-1.167h.584a1.167 1.167 0 1 1 0 2.333h-.584a1.167 1.167 0 0 1-1.166-1.166m4.083.002c0-.645.522-1.167 1.167-1.167h12.25a1.167 1.167 0 1 1 0 2.333H9.917A1.167 1.167 0 0 1 8.75 6.417m8.391 8.507a1.167 1.167 0 0 1 1.64-.01l2.613 2.549a1.167 1.167 0 1 1-1.63 1.67l-1.789-1.744-3.877 3.87a1.167 1.167 0 0 1-1.631.018l-3.01-2.882-6.294 6.36a1.167 1.167 0 0 1-1.659-1.642l7.103-7.175a1.167 1.167 0 0 1 1.636-.022l3.014 2.887z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgAnalysis: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-analysis", props.className)} />
));

export default SvgAnalysis;
