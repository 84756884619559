import { compile } from "path-to-regexp";

import { debug } from "./helpers";

export enum RoutePathName {
    home = "home",
    login = "login",
    notFound = "notFound",
    forgottenPassword = "forgottenPassword",
    resetPassword = "resetPassword",

    dashboard = "dashboard",
    biPerformedTourOrders = "biPerformedTourOrders",
    biSupportShipped = "biSupportShipped",
    biPunctualityRate = "biPunctualityRate",
    biFillingRate = "biFillingRate",
    biDeliveryConformityRate = "biDeliveryConformityRate",
    biPalletConformityRate = "biPalletConformityRate",
    biAverageTourOrdersPerDriverPerDay = "biAverageTourOrdersPerDriverPerDay",
    biReverseSupportRate = "biReverseSupportRate",
    biCarriersServiceRate = "biCarriersServiceRate",
    biPerformedDeliveries = "biPerformedDeliveries",

    chooseHub = "chooseHub",
    tourOrdersList = "tourOrdersList",
    tourOrdersInProgressMap = "tourOrdersInProgressMap",
    tourOrderDetails = "tourOrderDetails",
    storesList = "storesList",
    carriersList = "carriersList",
    trailersList = "trailersList",
    driversList = "driversList",
    settings = "settings",
    usersList = "usersList",
    valueListItems = "valueListItems",
    warehousesAndPlatforms = "warehousesAndPlatforms",
    tourOrderAnomaliesList = "tourOrderAnomaliesList",
    shippingManagment = "shippingManagment",
    returnedSupports = "returnedSupports",

    // Packaging Portal
    storePortalTourOrdersList = "storePortalTourOrdersList",
    storePortalTourOrderDetails = "storePortalTourOrderDetails",
    packagingPortalReturnedSupports = "packagingPortalReturnedSupports",

    // Store Portal
    storePortalReturnedSupporList = "storePortalReturnedSupporList",

    // Super Admin
    applicationList = "applicationList",
    organizationList = "organizationList",
    valueListList = "valueListList",
    roleList = "roleList",
    jobList = "jobList",
    ortecImportRouteError = "ortecImportRouteError",
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.home]: "/",
    [RoutePathName.login]: "/login",
    [RoutePathName.notFound]: "/404",
    [RoutePathName.forgottenPassword]: "/mot-de-passe-oublie",
    [RoutePathName.resetPassword]: "/reset-password",

    // Dashboard
    [RoutePathName.dashboard]: "/dashboard",
    [RoutePathName.biPerformedTourOrders]: "/dashboard/performed-tour-orders",
    [RoutePathName.biPunctualityRate]: "/dashboard/punctuality-rate",
    [RoutePathName.biFillingRate]: "/dashboard/filling-rate",
    [RoutePathName.biDeliveryConformityRate]: "/dashboard/delivery-conformity-rate",
    [RoutePathName.biPalletConformityRate]: "/dashboard/pallet-conformity-rate",
    [RoutePathName.biAverageTourOrdersPerDriverPerDay]: "/dashboard/average-tour-orders-per-driver-per-day",
    [RoutePathName.biReverseSupportRate]: "/dashboard/reverse-support-rate",
    [RoutePathName.biCarriersServiceRate]: "/dashboard/carriers-service-rate",
    [RoutePathName.biSupportShipped]: "/dashboard/support-shipped",
    [RoutePathName.biPerformedDeliveries]: "/dashboard/performed-deliveries",

    // Management
    [RoutePathName.chooseHub]: "/centrale",
    [RoutePathName.tourOrdersList]: "/pilotage/tours",
    [RoutePathName.tourOrdersInProgressMap]: "/pilotage/tours/carte-ot-en-cours",
    [RoutePathName.tourOrderDetails]: "/pilotage/tours/:tourOrderId",
    [RoutePathName.shippingManagment]: "/suivi-expeditions",
    [RoutePathName.storesList]: "/magasins",
    [RoutePathName.carriersList]: "/transporteurs",
    [RoutePathName.trailersList]: "/remorques",
    [RoutePathName.driversList]: "/chauffeurs",
    [RoutePathName.settings]: "/parametres/listes-de-valeurs",
    [RoutePathName.usersList]: "/parametres/utilisateurs",
    [RoutePathName.warehousesAndPlatforms]: "/parametres/listes-de-valeurs/warehousesAndPlatforms",
    [RoutePathName.valueListItems]: "/parametres/listes-de-valeurs/:valueListSlug",
    [RoutePathName.returnedSupports]: "/pilotage/emballages-en-retour",
    [RoutePathName.tourOrderAnomaliesList]: "/pilotage/anomalies",

    // Packaging Portal
    [RoutePathName.packagingPortalReturnedSupports]: "/emballages/emballages-en-retour",

    // Store Portal
    [RoutePathName.storePortalTourOrdersList]: "/magasin/tours",
    [RoutePathName.storePortalTourOrderDetails]: "/magasin/tours/:tourOrderId",
    [RoutePathName.storePortalReturnedSupporList]: "/magasin/emballages-en-retour",

    // Super Admin
    [RoutePathName.organizationList]: "/super-admin/organizations",
    [RoutePathName.applicationList]: "/super-admin/applications",
    [RoutePathName.valueListList]: "/super-admin/values-lists",
    [RoutePathName.roleList]: "/super-admin/roles",
    [RoutePathName.jobList]: "/super-admin/jobs",

    [RoutePathName.ortecImportRouteError]: "/super-admin/ortec-import-route-error",
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return "/404";
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string,
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error("[getRoute] route compile error :", error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
