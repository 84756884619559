import { FC, FunctionComponent, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import "./assets/styles/App.less";

import { Permission } from "./queries/api/types";
import { useLogs } from "./context/LogsContext";
import lazyImport from "./helpers/import";
import { getRawRoute, RoutePathName } from "./routes";
import ProtectedRoute from "./components/ProtectedRoute";
import MainLayout from "./components/MainLayout";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./pages/error";
import PermissionRoute, { PermissionRouteProps } from "./components/PermissionRoute";
import Maintenance from "./components/Maintenance";
import PageSpinner from "./components/PageSpinner";

const Login = lazyImport(() => import("./pages/login"));
const ForgottenPassword = lazyImport(() => import("./pages/forgottenPassword"));
const ResetPassword = lazyImport(() => import("./pages/resetPassword"));
const Home = lazyImport(() => import("./pages/home"));
const ApplicationsList = lazyImport(() => import("./pages/superAdmin/applications"));
const ValueList = lazyImport(() => import("./pages/superAdmin/valueList"));
const RoleList = lazyImport(() => import("./pages/superAdmin/roles"));
const JobList = lazyImport(() => import("./pages/superAdmin/jobs"));
const OrganizationsList = lazyImport(() => import("./pages/superAdmin/organizations"));
const OrtecRouteErrorsList = lazyImport(() => import("./pages/superAdmin/ortecRouteErrorsList"));
const StoresList = lazyImport(() => import("./pages/storesList"));
const CarriersList = lazyImport(() => import("./pages/carriersList"));
const Settings = lazyImport(() => import("./pages/settings/settings"));
const WhareHouseAndPlatforms = lazyImport(
    () => import("./pages/settings/valueListItems/WarehousesAndPlatformsValueList"),
);
const ValueListItems = lazyImport(() => import("./pages/settings/valueListItems"));
const TrailersList = lazyImport(() => import("./pages/trailersList"));
const DriversList = lazyImport(() => import("./pages/driversList"));
const TourOrdersList = lazyImport(() => import("./pages/management/tourOrdersList"));
const TourOrdersInProgressMap = lazyImport(() => import("./pages/management/tourOrdersInProgressMap"));
const TourDetails = lazyImport(() => import("./pages/management/tourOrderDetails"));
const RouteAnomaly = lazyImport(() => import("./pages/management/tourOrderAnomaliesList/TourOrderAnomaliesList"));
const ReturnedSupportsList = lazyImport(() => import("./pages/management/returnedSupportsList"));
const usersList = lazyImport(() => import("./pages/settings/usersList"));
const ShippmentsMonitoring = lazyImport(() => import("./pages/management/shippingManagment"));
const Dashboard = lazyImport(() => import("./pages/dashboard/Dashboard"));
const PerformedTourOrdersStats = lazyImport(
    () => import("./pages/dashboard/performedTourOrders/PerformedTourOrdersStats"),
);
const SupportShippedStats = lazyImport(() => import("./pages/dashboard/supportShipped/SupportShippedStats"));
const PerformedDeliveriesStats = lazyImport(
    () => import("./pages/dashboard/performedDeliveries/PerformedDeliveriesStats"),
);
const PunctualityRateStats = lazyImport(() => import("./pages/dashboard/punctualityRate/PunctualityRateStats"));
const FillingRateStats = lazyImport(() => import("./pages/dashboard/fillingRate/FillingRateStats"));
const DeliveryConformityRateStats = lazyImport(
    () => import("./pages/dashboard/deliveryConformityRate/DeliveryConformityRateStats"),
);
const PalletConformityRateStats = lazyImport(
    () => import("./pages/dashboard/palletConformityRate/PalletConformityRateStats"),
);
const AverageTourOrdersPerDriverPerDayStats = lazyImport(
    () => import("./pages/dashboard/averageTourOrdersPerDriverPerDay/AverageTourOrdersPerDriverPerDayStats"),
);
const ReverseSupportRateStats = lazyImport(
    () => import("./pages/dashboard/reverseSupportRate/ReverseSupportRateStats"),
);
const CarriersServiceRateStats = lazyImport(
    () => import("./pages/dashboard/carriersServiceRate/CarriersServiceRateStats"),
);
const LoadDeckGL = lazyImport(() => import("./map/LoadDeckGL"));
const StorePortalReturnedSupporList = lazyImport(() => import("./pages/management/storePortalReturnedSupporList"));

const routes: Array<PermissionRouteProps & { Children: FunctionComponent }> = [
    {
        path: getRawRoute(RoutePathName.home),
        Children: Home,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.dashboard),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: Dashboard,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biPerformedTourOrders),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: PerformedTourOrdersStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biPerformedDeliveries),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: PerformedDeliveriesStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biSupportShipped),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: SupportShippedStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biPunctualityRate),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: PunctualityRateStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biFillingRate),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: FillingRateStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biDeliveryConformityRate),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: DeliveryConformityRateStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biPalletConformityRate),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: PalletConformityRateStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biAverageTourOrdersPerDriverPerDay),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: AverageTourOrdersPerDriverPerDayStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biReverseSupportRate),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: ReverseSupportRateStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.biCarriersServiceRate),
        permissions: [{ permission: Permission.dashboardUI }],
        Children: CarriersServiceRateStats,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.tourOrdersList),
        permissions: [
            { permission: Permission.tourOrdersMonitoringUI },
            { permission: Permission.storePortalTourOrderMonitoringUI },
        ],
        Children: TourOrdersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.tourOrdersInProgressMap),
        permissions: [{ permission: Permission.tourOrdersMonitoringUI }],
        Children: TourOrdersInProgressMap,
    },
    {
        path: getRawRoute(RoutePathName.tourOrderDetails),
        permissions: [
            { permission: Permission.tourOrdersDetailUI },
            { permission: Permission.storePortalTourOrderMonitoringUI },
        ],
        Children: TourDetails,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.returnedSupports),
        permissions: [{ permission: Permission.returnPackagingUI }],
        Children: ReturnedSupportsList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.packagingPortalReturnedSupports),
        permissions: [{ permission: Permission.packagingPortalReturnPackagingUI }],
        Children: ReturnedSupportsList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.storesList),
        permissions: [{ permission: Permission.storeManagementUI }],
        Children: StoresList,
    },
    {
        path: getRawRoute(RoutePathName.carriersList),
        permissions: [{ permission: Permission.carrierManagementUI }],
        Children: CarriersList,
    },
    {
        path: getRawRoute(RoutePathName.trailersList),
        permissions: [{ permission: Permission.trailerManagementUI }],
        Children: TrailersList,
    },
    {
        path: getRawRoute(RoutePathName.settings),
        permissions: [{ permission: Permission.settingsUI }],
        Children: Settings,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.usersList),
        permissions: [{ permission: Permission.userManagementUI }],
        Children: usersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.warehousesAndPlatforms),
        permissions: [{ permission: Permission.settingsUI }],
        Children: WhareHouseAndPlatforms,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.valueListItems),
        permissions: [{ permission: Permission.settingsUI }],
        Children: ValueListItems,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.ortecImportRouteError),
        permissions: [{ permission: Permission.superAdmin }],
        Children: OrtecRouteErrorsList,
    },
    {
        path: getRawRoute(RoutePathName.driversList),
        permissions: [{ permission: Permission.driverManagementUI }],
        Children: DriversList,
    },
    {
        path: getRawRoute(RoutePathName.shippingManagment),
        permissions: [{ permission: Permission.tourOrdersShippingManagerUI }],
        Children: ShippmentsMonitoring,
    },
    {
        path: getRawRoute(RoutePathName.applicationList),
        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.applicationsUI }],
        Children: ApplicationsList,
    },
    {
        path: getRawRoute(RoutePathName.organizationList),
        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.organizationsUI }],
        Children: OrganizationsList,
    },
    {
        path: getRawRoute(RoutePathName.valueListList),
        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.valueListsUI }],
        Children: ValueList,
    },
    {
        path: getRawRoute(RoutePathName.roleList),
        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.rolesUI }],
        Children: RoleList,
    },
    {
        path: getRawRoute(RoutePathName.jobList),
        permissions: [{ permission: Permission.superAdmin }],
        Children: JobList,
    },
    {
        path: getRawRoute(RoutePathName.tourOrderAnomaliesList),
        permissions: [{ permission: Permission.tourOrdersAnomalyUI }],
        Children: RouteAnomaly,
    },
    {
        path: getRawRoute(RoutePathName.storePortalTourOrdersList),
        permissions: [{ permission: Permission.storePortalTourOrderMonitoringUI }],
        Children: TourOrdersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.storePortalTourOrderDetails),
        permissions: [{ permission: Permission.storePortalTourOrderMonitoringUI }],
        Children: TourDetails,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.storePortalReturnedSupporList),
        permissions: [{ permission: Permission.storePortalReturnPackagingUI }],
        Children: StorePortalReturnedSupporList,
    },
];

const App: FC = () => {
    const { sendLogs } = useLogs();
    return (
        <ErrorBoundary sendLogs={sendLogs}>
            <Maintenance />
            <Suspense fallback={<PageSpinner isFixed />}>
                <LoadDeckGL />
                <Switch>
                    <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
                    <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
                    <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
                    <ProtectedRoute>
                        <MainLayout>
                            <Suspense fallback={<PageSpinner />}>
                                <ErrorBoundary sendLogs={sendLogs}>
                                    <Switch>
                                        {routes.map(({ Children, ...props }) => (
                                            <PermissionRoute
                                                key={
                                                    Array.isArray(props.path)
                                                        ? props.path.join("")
                                                        : (props.path as string | undefined)
                                                }
                                                {...props}
                                            >
                                                <Children />
                                            </PermissionRoute>
                                        ))}

                                        <Route path="*">
                                            <ErrorPage />
                                        </Route>
                                    </Switch>
                                </ErrorBoundary>
                            </Suspense>
                        </MainLayout>
                    </ProtectedRoute>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default App;
