import { VFC, useCallback, useMemo, useRef } from "react";
import Table, { SortingState, TableColumn, TableProps, getDefaultSort } from "@oneui/table";
import NoResult from "@oneui/no-result";
import Button from "@oneui/button";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Tooltip } from "@oneui/tooltip";
import { useModal } from "@ebay/nice-modal-react";

import Seo from "../../../components/Seo";
import ListTitle from "../../../components/ListTitle";
import {
    TourOrder,
    TourOrderStatus,
    ReturnedSupportColumnKey,
    TableTemplateKey,
    Permission,
    PermissionRight,
} from "../../../queries/api/types";
import { formatValue, getFullName } from "../../../helpers";
import useQueryParams from "../../../hooks/queryParams";
import { useAuth } from "../../../context/AuthContext";
import { RoutePathName, getRoute } from "../../../routes";
import { ColorTagColor } from "../../../components/ColorTag";
import ColorTag from "../../../components/ColorTag";
import { returnedSupportListColumnHeaderMap } from "../../../i18n/tourOrders";
import ReturnedSupportsListFilters from "./ReturnedSupportsListFilters";
import ApiResult from "../../../components/ApiResult";
import { useTourOrdersList } from "../../../queries/tourOrders";
import { hasPermission } from "../../../helpers/security";
import { formatNumber, formatTime } from "../../../i18n";
import { useElementDimensions } from "../../../hooks/useElementDimensions";
import ReturnedSupportTag from "../../../components/ReturnedSupportTag";
import ReturnedSupportStatusTag from "./ReturnedSupportStatusTag";
import { Check } from "../../../components/icons";
import ReturnedSupportsFormDrawer from "./ReturnedSupportsFormDrawer";
import { isTrailerDepotTask } from "../../../helpers/type";

export interface ActiveFiltersShape {
    page: number;
    sort: string;
    sortOrder: string;
    search?: string;
    from: string;
    to: string;
    status?: TourOrderStatus;
    store?: string;
    carrier?: string;
    driver?: string;
    activity?: string;
    typology?: string;
}

export const allReturnedSupportsStatuses = [
    TourOrderStatus.onReturnWithSupports,
    TourOrderStatus.unloadingSupports,
    TourOrderStatus.supportsUnloaded,
    TourOrderStatus.overhanging,
    TourOrderStatus.completedWithSupportsUnloaded,
];

const formatStatusFilter = (status?: string) => {
    if (status && allReturnedSupportsStatuses.includes(status)) {
        return status as TourOrderStatus;
    }

    return allReturnedSupportsStatuses;
};

const ReturnedSupportsList: VFC = () => {
    const { user } = useAuth();
    const history = useHistory();
    const [queryParams, setQueryParams] = useQueryParams("returned-supports-list");
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const sort = queryParams.get("sort") ?? "createdAt";
    const sortOrder = queryParams.get("sortOrder") ?? "desc";
    const search = queryParams.get("search") ?? undefined;
    const from = queryParams.get("from") ?? dayjs().startOf("day").toISOString();
    const to = queryParams.get("to") ?? dayjs().add(1, "day").endOf("day").toISOString();
    const store = queryParams.get("store") ?? undefined;
    const carrier = queryParams.get("carrier") ?? undefined;
    const driver = queryParams.get("driver") ?? undefined;
    const typology = queryParams.get("typology") ?? undefined;
    const activeFilters = useMemo<ActiveFiltersShape>(
        () => ({
            page,
            sort,
            sortOrder,
            search,
            from,
            to,
            store,
            carrier,
            driver,
            typology,
        }),
        [carrier, driver, from, page, search, sort, sortOrder, store, to, typology],
    );
    const returnedSupportsFormDrawer = useModal(ReturnedSupportsFormDrawer);
    const canValidateReturnedSupports = hasPermission(user, Permission.tourOrdersReturnSupports, PermissionRight.write);

    const {
        data: tourOrdersListData,
        isLoading,
        isFetching,
        isError,
        error,
    } = useTourOrdersList({
        ...{ ...activeFilters, typology: undefined },
        status: formatStatusFilter(typology),
        search: search && search?.length >= 3 ? search : undefined,
    });

    const userReturnedSupportsColumns = user?.tableTemplates?.find(
        (template) => template.key === TableTemplateKey.supportReturn,
    )?.columns;

    const showActionColumn = useMemo(() => {
        return (
            canValidateReturnedSupports &&
            tourOrdersListData?.items.every(
                (tourOrder) => tourOrder.status !== TourOrderStatus.completedWithSupportsUnloaded,
            )
        );
    }, [canValidateReturnedSupports, tourOrdersListData]);

    const tableColumns = useMemo<Array<TableColumn<TourOrder>>>(
        () =>
            (
                [
                    ...Object.values(ReturnedSupportColumnKey)
                        .sort(
                            (a, b) =>
                                (userReturnedSupportsColumns?.find((cols) => cols.key === a)?.index ?? 0) -
                                (userReturnedSupportsColumns?.find((cols) => cols.key === b)?.index ?? 0),
                        )
                        .map((key, index) => {
                            const sortKey = userReturnedSupportsColumns?.find((cols) => cols.key === key)?.sortKey;
                            let column: TableColumn<TourOrder> = {
                                id: sortKey ?? key,
                                header: returnedSupportListColumnHeaderMap.get(key),
                                fixed: index === 0 ? "left" : undefined,
                                enableSorting: !!sortKey,
                            };
                            switch (key) {
                                case ReturnedSupportColumnKey.reference:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const { id, reference } = info.getValue<TourOrder>();
                                            const canSeeDetails = hasPermission(user, Permission.tourOrdersDetailUI);
                                            return canSeeDetails ? (
                                                <Button
                                                    type="link"
                                                    className="!no-underline"
                                                    onClick={() =>
                                                        history.push(
                                                            getRoute(RoutePathName.tourOrderDetails, {
                                                                tourOrderId: id,
                                                            }),
                                                        )
                                                    }
                                                >
                                                    {reference}
                                                </Button>
                                            ) : (
                                                reference
                                            );
                                        },
                                        size: 150,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.date:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => dayjs(info.getValue<TourOrder>().date).format("DD/MM/YYYY"),
                                        size: 110,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.status:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const tourOrder = info.getValue<TourOrder>();
                                            return <ReturnedSupportStatusTag tourOrder={tourOrder} />;
                                        },
                                        size: 150,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.returnLocation:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            return formatValue(
                                                info.getValue<TourOrder>().computedProperties.returnLocation,
                                            );
                                        },
                                    };
                                    break;
                                case ReturnedSupportColumnKey.initialStartTime:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const tourOrder = info.getValue<TourOrder>();
                                            const trailerDepotTask = tourOrder.tasks.filter(isTrailerDepotTask);
                                            const lastTrailerDepotTask = trailerDepotTask.slice(-1)[0];
                                            return lastTrailerDepotTask
                                                ? formatTime(lastTrailerDepotTask.initialStartTime)
                                                : undefined;
                                        },
                                        size: 166,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.startedTime:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const tourOrder = info.getValue<TourOrder>();
                                            const trailerDepotTask = tourOrder.tasks.filter(isTrailerDepotTask);
                                            const lastTrailerDepotTask = trailerDepotTask.slice(-1)[0];
                                            return lastTrailerDepotTask
                                                ? formatTime(lastTrailerDepotTask.startedTime)
                                                : undefined;
                                        },
                                    };
                                    break;
                                case ReturnedSupportColumnKey.driver:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const { driverFirstName: firstName, driverLastName: lastName } =
                                                info.getValue<TourOrder>().computedProperties;
                                            return getFullName({ firstName, lastName }) || "-";
                                        },
                                        size: 190,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.trailer:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const { trailerReference, trailerRegistration, trailerCreatedInTourOrder } =
                                                info.getValue<TourOrder>().computedProperties;
                                            if (trailerCreatedInTourOrder) {
                                                return trailerRegistration ?? "";
                                            }
                                            return `${trailerReference || ""} ${
                                                trailerRegistration ? `(${trailerRegistration})` : ""
                                            }`;
                                        },
                                    };
                                    break;
                                case ReturnedSupportColumnKey.stores:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const { stores } = info.getValue<TourOrder>().computedProperties;
                                            const uniqueStores = Array.from(
                                                new Set(stores?.map((store) => store.reference)),
                                            );
                                            return (
                                                <div className="flex flex-wrap gap-8">
                                                    {uniqueStores?.map((storeRef) => {
                                                        const store = stores?.find((s) => s.reference === storeRef);
                                                        return store ? (
                                                            <Tooltip key={store.reference} content={store.name}>
                                                                <span>
                                                                    <ColorTag color={ColorTagColor.white} size="small">
                                                                        {store.reference}
                                                                    </ColorTag>
                                                                </span>
                                                            </Tooltip>
                                                        ) : null;
                                                    })}
                                                </div>
                                            );
                                        },
                                        size: 150,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.totalReturnedSupports:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) =>
                                            formatNumber(
                                                info.getValue<TourOrder>().computedProperties.totalReturnedSupports,
                                            ),
                                        size: 100,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.returnedSupports:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => {
                                            const returnedSupports =
                                                info.getValue<TourOrder>().computedProperties.returnedSupports;
                                            return returnedSupports?.length ? (
                                                <div className="flex flex-wrap gap-8">
                                                    {returnedSupports.map((support) => (
                                                        <ReturnedSupportTag
                                                            key={support.support}
                                                            returnedSupport={support}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                "-"
                                            );
                                        },
                                        size: 260,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.truckRegistration:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) => formatValue(info.getValue<TourOrder>().truckRegistration),
                                        size: 120,
                                    };
                                    break;
                                case ReturnedSupportColumnKey.carrier:
                                    column = {
                                        ...column,
                                        accessorFn: (info) => info,
                                        cell: (info) =>
                                            formatValue(info.getValue<TourOrder>().computedProperties.carrierName),
                                    };
                                    break;
                            }
                            return column;
                        }),
                    showActionColumn && {
                        id: "actions",
                        header: "Actions",
                        fixed: "right",
                        accessorFn: (info) => info,
                        cell: (info) => {
                            const tourOrder = info.getValue<TourOrder>();
                            const { status, id } = tourOrder;

                            return (
                                <Button
                                    size="small"
                                    type="secondary"
                                    iconOnly={<Check />}
                                    onClick={() => returnedSupportsFormDrawer.show({ tourOrderId: id })}
                                    disabled={
                                        ![TourOrderStatus.unloadingSupports, TourOrderStatus.overhanging].includes(
                                            status,
                                        )
                                    }
                                />
                            );
                        },
                        size: 87,
                    },
                ] as Array<TableColumn<TourOrder>>
            ).filter(Boolean),
        [history, user, userReturnedSupportsColumns, returnedSupportsFormDrawer, showActionColumn],
    );

    const defaultSortingState = useMemo(() => getDefaultSort(sort, sortOrder), [sort, sortOrder]);
    const onSortChange = useCallback(
        (sortingState: SortingState) => {
            setQueryParams({
                ...activeFilters,
                sort: sortingState[0]?.id,
                sortOrder: sortingState[0] ? (sortingState[0].desc ? "desc" : "asc") : undefined,
            });
        },
        [activeFilters, setQueryParams],
    );
    const pagination = useMemo<TableProps<TourOrder>["pagination"]>(
        () => ({
            current: page + 1,
            total: tourOrdersListData?.totalCount ?? 0,
            onChange: (current, pageSize) => {
                const newPage = current - 1 === 0 ? undefined : current - 1;
                setQueryParams({
                    ...activeFilters,
                    page: newPage,
                    pageSize,
                });
            },
            pageSize: 50,
            isScrollingToTopOnChange: true,
            inTableFooter: true,
            size: "small",
            hideOnSinglePage: true,
        }),
        [activeFilters, page, setQueryParams, tourOrdersListData?.totalCount],
    );

    const tableContainerRef = useRef(null);
    const { height: tableMaxHeight } = useElementDimensions(tableContainerRef) || {};

    return (
        <>
            <Seo title="Liste des tours" />
            <div className="flex h-full flex-col">
                <div className="mb-24 flex">
                    <ListTitle className="!mb-0 !mr-56 text-nowrap" count={tourOrdersListData?.totalCount}>
                        Emballages en retour
                    </ListTitle>
                </div>
                <div className="flex w-full flex-1 flex-col gap-16">
                    <ReturnedSupportsListFilters activeFilters={activeFilters} />
                    <div className="relative flex-1" ref={tableContainerRef}>
                        {isError ? (
                            <ApiResult status={error?.response?.status} />
                        ) : (
                            <div className="absolute inset-0">
                                <Table<TourOrder>
                                    key={`${sort}-${sortOrder}`}
                                    columns={tableColumns}
                                    isLoading={isLoading || isFetching}
                                    data={tourOrdersListData?.items}
                                    defaultSortingState={defaultSortingState}
                                    onSortChange={onSortChange}
                                    pagination={pagination}
                                    minWidthBeforeScroll={1426}
                                    maxHeight={tableMaxHeight}
                                    emptyContent={
                                        <NoResult
                                            description={
                                                search
                                                    ? "Aucun emballages en retour correspondant à votre recherche"
                                                    : "Aucun emballages en retour"
                                            }
                                        />
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReturnedSupportsList;
