import { downloadZip } from "client-zip";

import { downloadFile } from ".";

export const zipAndDownload = async (files: Array<{ filename: string; file: Blob }>, filename?: string) => {
    const iterator = files.map(({ filename, file }) => ({ name: filename, input: file }))[Symbol.iterator]();
    const blob = await downloadZip(iterator).blob();

    const zipURL = URL.createObjectURL(blob);

    downloadFile(zipURL, filename);

    URL.revokeObjectURL(zipURL);
};
