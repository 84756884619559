/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.917 1.167c.644 0 1.166.522 1.166 1.166V3.5h5.834V2.333a1.167 1.167 0 0 1 2.333 0V3.5h5.25a2.333 2.333 0 0 1 2.333 2.333V24.5a2.333 2.333 0 0 1-2.333 2.333h-21A2.333 2.333 0 0 1 1.167 24.5V5.833A2.333 2.333 0 0 1 3.5 3.5h5.25V2.333c0-.644.522-1.166 1.167-1.166M8.75 5.833H3.5V10.5h21V5.833h-5.25V7a1.167 1.167 0 0 1-2.333 0V5.833h-5.834V7A1.167 1.167 0 1 1 8.75 7zm15.75 7h-5.25V17.5h5.25zm0 7h-5.25V24.5h5.25zM16.917 24.5v-4.667h-5.834V24.5zm-8.167 0v-4.667H3.5V24.5zm-5.25-7h5.25v-4.667H3.5zm7.583-4.667V17.5h5.834v-4.667z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCalendarThree: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-calendar-three", props.className)} />
));

export default SvgCalendarThree;
