import { VFC, useMemo, useState } from "react";
import { Divider, Popover } from "antd";
import Button from "@oneui/button";
import Tooltip from "@oneui/tooltip";

import { useAuth } from "../context/AuthContext";
import { formatPhoneNumber, getFullName } from "../helpers";
import { Copy, Mail, Phone, QrCode } from "./icons";
import { successMessage } from "../helpers/message";
import { downloadStoreQRcodes } from "../helpers/qrCode";

const HeaderStoreInfos: VFC = () => {
    const { user } = useAuth();
    const { store } = user || {};
    const [phoneOpen, setPhoneOpen] = useState(false);
    const [mailOpen, setMailOpen] = useState(false);

    const hidePhone = () => {
        setPhoneOpen(false);
    };

    const handlePhoneOpenChange = (newState: boolean) => {
        setPhoneOpen(newState);
    };

    const hideMail = () => {
        setMailOpen(false);
    };

    const handleMailOpenChange = (newState: boolean) => {
        setMailOpen(newState);
    };

    const mainContact = useMemo(() => store?.contacts?.[0], [store?.contacts]);

    if (!store) {
        return null;
    }

    return (
        <div className="flex items-center leading-5">
            <Divider type="vertical" className="!mx-24 !h-32" />
            <div className="flex gap-32">
                <div className="flex items-center gap-16">
                    <div>
                        <p className="text-2xs font-extrabold uppercase text-brand-neutral-grey">Votre magasin</p>
                        <p className="text-base font-bold text-brand-blue-600">{`${store.reference} - ${store.name}`}</p>
                    </div>
                    <Tooltip content="Télécharger le QR code du/des quais de livraison">
                        <Button
                            size="small"
                            type="secondary"
                            iconOnly={<QrCode />}
                            onClick={() => downloadStoreQRcodes(store)}
                        />
                    </Tooltip>
                </div>
                <div className="flex items-center gap-16">
                    <div>
                        <p className="text-2xs font-extrabold uppercase text-brand-neutral-grey">Contact principal</p>
                        <p className="text-base font-bold text-brand-blue-600">
                            {mainContact ? getFullName(mainContact) : "-"}
                        </p>
                    </div>
                    <div className="flex gap-8">
                        <Popover
                            open={phoneOpen}
                            onOpenChange={handlePhoneOpenChange}
                            content={
                                <span className="flex items-center gap-16">
                                    {formatPhoneNumber(mainContact?.phone)}
                                    <Copy
                                        onClick={() =>
                                            navigator.clipboard.writeText(String(mainContact?.phone)).then(() => {
                                                hidePhone();
                                                successMessage({
                                                    content: "Numéro de téléphone copié dans le presse-papier",
                                                });
                                            })
                                        }
                                    />
                                </span>
                            }
                        >
                            <Button size="small" type="secondary" iconOnly={<Phone />} disabled={!mainContact?.phone} />
                        </Popover>
                        <Popover
                            open={mailOpen}
                            onOpenChange={handleMailOpenChange}
                            content={
                                <span className="flex items-center gap-16">
                                    {mainContact?.email}
                                    <Copy
                                        onClick={() =>
                                            navigator.clipboard.writeText(String(mainContact?.email)).then(() => {
                                                hideMail();
                                                successMessage({ content: "Mail copié dans le presse-papier" });
                                            })
                                        }
                                    />
                                </span>
                            }
                        >
                            <Button size="small" type="secondary" iconOnly={<Mail />} disabled={!mainContact?.email} />
                        </Popover>
                    </div>
                </div>
            </div>

            <Divider type="vertical" className="!mx-24 !h-32" />
        </div>
    );
};

export default HeaderStoreInfos;
