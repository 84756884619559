import {
    TourOrderStatus,
    TourOrderColumnKey,
    LoadingTaskStatus,
    DeliveryTaskStatus,
    ShippingManagementColumnKey,
    TourOrderDriverType,
    LoadingTaskEvent,
    DeliveryTaskEvent,
    TrailerDepotTaskStatus,
    ReturnedSupportColumnKey,
    StorePortalTourOrderColumnKey,
} from "../queries/api/types";

export const tourOrderDriverTypeMap = new Map([
    [TourOrderDriverType.holder, "Titulaire"],
    [TourOrderDriverType.substitute, "Supp."],
]);

export const shippingManagmentLoadingTaskStatusMap = new Map([
    [LoadingTaskStatus.todo, "Prêt à démarrer"],
    [LoadingTaskStatus.loading, "En chargement"],
    [LoadingTaskStatus.loaded, "Chargé"],
    [LoadingTaskStatus.canceled, "Annulé"],
]);

export const tourOrderTaskStatusMap = new Map([
    ["todo", "À venir"],
    [LoadingTaskStatus.loading, "En chargement"],
    [LoadingTaskStatus.preloaded, "Pré-chargé"],
    [LoadingTaskStatus.loaded, "Chargé"],
    [DeliveryTaskStatus.onDelivery, "En livraison"],
    [DeliveryTaskStatus.onTheWay, "En livraison"],
    [DeliveryTaskStatus.arrived, "Arrivé"],
    [DeliveryTaskStatus.delivered, "Livré"],
    [DeliveryTaskStatus.declined, "Refusée"],
    [TrailerDepotTaskStatus.readyToUnload, "En cours"],
    [TrailerDepotTaskStatus.unloadingSupport, "En cours"],
    ["inProgress", "En cours"],
    ["completed", "Terminée"],
    ["canceled", "Annulé"],
    ["reported", "Reporté"],
]);

export const tourOrderStatusMap = new Map([
    [TourOrderStatus.readyToStart, "Prêt à démarrer"],
    [TourOrderStatus.loading, "En chargement"],
    [TourOrderStatus.loaded, "Chargé"],
    [TourOrderStatus.virtuallyLoaded, "Chargé"],
    [TourOrderStatus.onDelivery, "En livraison"],
    [TourOrderStatus.onReturn, "En retour"],
    [TourOrderStatus.onReturnWithSupports, "En retour"],
    [TourOrderStatus.unloadingSupports, "En retour"],
    [TourOrderStatus.completed, "Terminé"],
    [TourOrderStatus.completedWithSupportsUnloaded, "Terminé"],
    [TourOrderStatus.canceled, "Annulé"],
    [TourOrderStatus.onReturnWithSupports, "En retour"],
    [TourOrderStatus.unloadingSupports, "En déchargement"],
    [TourOrderStatus.overhanging, "En débord"],
    [TourOrderStatus.supportsUnloaded, "Déchargé"],
    [TourOrderStatus.completedWithSupportsUnloaded, "Déchargé"],
]);

export const tourOrderListColumnHeaderMap = new Map([
    [TourOrderColumnKey.reference, "OT"],
    [TourOrderColumnKey.date, "Date"],
    [TourOrderColumnKey.status, "Statut"],
    [TourOrderColumnKey.driver, "Chauffeur"],
    [TourOrderColumnKey.startTime, "Début OT"],
    [TourOrderColumnKey.scheduledDeparture, "Départ prévu"],
    [TourOrderColumnKey.actualDeparture, "Départ réel"],
    [TourOrderColumnKey.tasks, "Tâches"],
    [TourOrderColumnKey.stores, "Magasins"],
    [TourOrderColumnKey.pallets, "Palettes"],
    [TourOrderColumnKey.activities, "Activités"],
    [TourOrderColumnKey.platforms, "Quais"],
    [TourOrderColumnKey.trailer, "Remorque"],
    [TourOrderColumnKey.carrier, "Transporteur"],
    [TourOrderColumnKey.yardDrivers, "Conducteurs de cour"],
    [TourOrderColumnKey.comments, "Commentaires"],
]);

export const storePortaltourOrderListColumnHeaderMap = new Map([
    [StorePortalTourOrderColumnKey.reference, "OT"],
    [StorePortalTourOrderColumnKey.date, "Date"],
    [StorePortalTourOrderColumnKey.status, "Statut"],
    [StorePortalTourOrderColumnKey.scheduledDeparture, "Départ prévu"],
    [StorePortalTourOrderColumnKey.actualDeparture, "Départ réel"],
    [StorePortalTourOrderColumnKey.startTime, "Arrivée prévue"],
    [StorePortalTourOrderColumnKey.pallets, "Palettes"],
    [StorePortalTourOrderColumnKey.activities, "Activités"],
    [StorePortalTourOrderColumnKey.driver, "Chauffeur"],
    [StorePortalTourOrderColumnKey.carrier, "Transporteur"],
]);

export const returnedSupportListColumnHeaderMap = new Map([
    [ReturnedSupportColumnKey.reference, "OT"],
    [ReturnedSupportColumnKey.date, "Date"],
    [ReturnedSupportColumnKey.status, "Statut"],
    [ReturnedSupportColumnKey.returnLocation, "Zone de débord"],
    [ReturnedSupportColumnKey.initialStartTime, "Retour planifié"],
    [ReturnedSupportColumnKey.startedTime, "Retour réel"],
    [ReturnedSupportColumnKey.driver, "Chauffeur"],
    [ReturnedSupportColumnKey.trailer, "Remorque"],
    [ReturnedSupportColumnKey.stores, "Magasins"],
    [ReturnedSupportColumnKey.totalReturnedSupports, "Emballages"],
    [ReturnedSupportColumnKey.returnedSupports, "Types d’emballages"],
    [ReturnedSupportColumnKey.truckRegistration, "Tracteur"],
    [ReturnedSupportColumnKey.carrier, "Transporteur"],
]);

export const shippingManagmentColumnHeaderMap = new Map([
    [ShippingManagementColumnKey.reference, "OT"],
    [ShippingManagementColumnKey.date, "Date"],
    [ShippingManagementColumnKey.stores, "Magasins"],
    [ShippingManagementColumnKey.platform, "Quai"],
    [ShippingManagementColumnKey.startTime, "Début de chrgt. prévu"],
    [ShippingManagementColumnKey.status, "Statut"],
    [ShippingManagementColumnKey.startedTime, "Début de chrgt. réel"],
    [ShippingManagementColumnKey.endTime, "Départ prévu"],
    [ShippingManagementColumnKey.endedTime, "Départ réel"],
    [ShippingManagementColumnKey.numberOfPalletsToLoad, "Palettes à charger"],
    [ShippingManagementColumnKey.loadingToBeFollowed, "Chgt à suivre"],
    [ShippingManagementColumnKey.loader, "Chargeur"],
    [ShippingManagementColumnKey.trailer, "Remorque"],
    [ShippingManagementColumnKey.comments, "Commentaires"],
]);

export const loadingTaskEventNameMap = new Map([
    [LoadingTaskEvent.arrivedAtThePlatform, "Arrivée au quai de chargement"],
    [LoadingTaskEvent.loadingStarted, "Chargement démarré"],
    [LoadingTaskEvent.loadingEnded, "Chargement terminé"],
    [LoadingTaskEvent.departureFromPlatform, "Départ du quai de chargement"],
    [LoadingTaskEvent.confirmDeliveryNote, "Validation du bon de livraison"],
    [LoadingTaskEvent.departureFromWarehouses, "Départ de la centrale"],
]);

export const deliveryTaskEventNameMap = new Map([
    [DeliveryTaskEvent.arrivedAtStore, "Arrivée au magasin"],
    [DeliveryTaskEvent.declinedDelivery, "Livraison refusée"],
    [DeliveryTaskEvent.deliveryStarted, "Livraison démarrée"],
    [DeliveryTaskEvent.delivered, "Livraison terminée"],
    [DeliveryTaskEvent.departureFromStore, "Départ du magasin"],
]);
