// Types of the backend API
type ObjectId = string;

export enum DeviceType {
    ios = "ios",
    android = "android",
    web = "web",
    koala = "koala",
    api = "api",
    nothing = "",
}

export enum OrganizationSlug {
    scapnor = "SCAPNOR",
}

export interface Organization {
    id: string;
    name: string;
    slug: OrganizationSlug;
    reference: string;
    applications?: Application[];
    hasBeenSeen: boolean;
    parent?: Organization;
    children?: Organization[];
    createdAt: string;
    updatedAt: string;
    logoUrl?: string;
    address: Address;
}

export enum PermissionRight {
    read = "r",
    write = "w",
    create = "c",
    delete = "d",
    disabled = "disabled",
}

export enum Permission {
    superAdmin = "superAdmin",
    admin = "admin",
    logAs = "logAs",
    users = "users",
    roles = "roles",
    backoffice = "backoffice",
    organizations = "organizations",
    applications = "applications",
    staticPages = "staticPages",
    questions = "questions",
    valueLists = "valueLists",
    tableTemplates = "tableTemplates",
    settings = "settings",
    notifications = "notifications",

    carriers = "carriers",
    drivers = "drivers",
    stores = "stores",
    trailers = "trailers",
    loadings = "loadings",
    ortecRouteError = "ortecRouteError",
    trimble = "trimble",

    // Tour Order specific permissions
    tourOrders = "tourOrders",
    tourOrdersTransport = "tourOrdersTransport",
    tourOrdersReturnSupports = "tourOrdersReturnSupports",
    tourOrdersHazardTask = "tourOrdersHazardTask",

    track = "track",

    // UI Permissions
    dashboardUI = "dashboardUI",
    tourOrdersMonitoringUI = "tourOrdersMonitoringUI",
    tourOrdersDetailUI = "tourOrdersDetailUI",
    tourOrdersInProgressUI = "tourOrdersInProgressUI",
    driversMonitoringUI = "driversMonitoringUI",
    returnPackagingUI = "returnPackagingUI",
    tourOrdersAnomalyUI = "tourOrdersAnomalyUI",
    tourOrdersOperatorUI = "tourOrdersOperatorUI",
    storeManagementUI = "storeManagementUI",
    carrierManagementUI = "carrierManagementUI",
    trailerManagementUI = "trailerManagementUI",
    driverManagementUI = "driverManagementUI",
    userManagementUI = "userManagementUI",
    settingsUI = "settingsUI",
    storePortalTourOrderMonitoringUI = "storePortalTourOrderMonitoringUI",
    storePortalReturnPackagingUI = "storePortalReturnPackagingUI",
    tourOrdersShippingManagerUI = "tourOrdersShippingManagerUI",
    packagingPortalReturnPackagingUI = "packagingPortalReturnPackagingUI",
    applicationsUI = "applicationsUI",
    organizationsUI = "organizationsUI",
    valueListsUI = "valueListsUI",
    rolesUI = "rolesUI",
    ortecRouteErrorUI = "ortecRouteErrorUI",
}

export enum RoleScope {
    organizations = "organizations",
    users = "users",
    groups = "groups",
}

export interface Scope {
    organizations: ObjectId[];
    users: ObjectId[];
}
export interface Role {
    id: ObjectId;
    name: string;
    slug: RoleSlug;
    isRemovable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}
export enum RoleSlug {
    superAdmin = "superAdmin",
    admin = "admin",
    operator = "operator",
    shippingManager = "shippingManager",
    packagingArea = "packagingArea",
    driver = "driver",
    store = "store",
}

export interface UserScope {
    // [key in RoleScope]?: ObjectId[];
    [RoleScope.organizations]?: Organization[];
    [RoleScope.users]?: User[];
}
export interface User {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    token?: string;
    phone?: string;
    mainStoreUser?: boolean;
    hasAcceptedTerms?: boolean;
    hasAcceptedTermsAt?: string;
    isDeleted?: boolean;
    deletedAt?: string;
    avatarUrl?: string;
    language?: SupportedLanguage;
    mainRole?: Role;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        // [key in RoleScope]?: ObjectId[];
        [RoleScope.organizations]?: ObjectId[] | Organization[];
        [RoleScope.users]?: ObjectId[] | User[];
    };
    organization?: Organization;
    carrier?: Carrier;
    tableTemplates?: TableTemplate[];
    warehouse?: ValueListItem;
    store?: Store;
    computedProperties: {
        organizationSlug?: string;
        organizationName?: string;

        roleSlug?: string;
        roleName?: string;

        carrierReference?: string;
        carrierName?: string;
    };
}

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}
export enum AddressType {
    personal = "personal",
    professional = "professional",
    other = "other",
}
export interface Address {
    id?: ObjectId;
    type?: AddressType;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    oneLineAddress?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    hasEelevator?: boolean;
    hasIntercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export enum SupportedLanguage {
    fr = "fr",
    // en = "en",
    // it = "it",
    // es = 'es',
    // du = 'du',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export enum SortOrder {
    asc = "asc",
    desc = "desc",
}

export const LanguagesList: SupportedLanguage[] = [SupportedLanguage.fr];

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ApiInfos {
    name: string;
    version: string;
    env: "development" | "production" | "preprod";
    instance: string;
    request: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface QuestionSection {
    id: ObjectId;
    title: Translation | string;
    description?: Translation | string;
    questionCount?: number;
    questions?: Question[];
}

export interface Question {
    id: ObjectId;
    section?: QuestionSection;
    title: Translation | string;
    description?: Translation | string;
    answer: Translation | string;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

export enum NotificationStatus {
    pending = "pending", // not useful here
    sent = "sent", // not useful here
    received = "received",
    opened = "opened",
    failed = "failed", // not useful here
}

export enum NotificationContextType {
    comment = "comment",
    otRetarded = "otRetarded",
    exceptionalHazard = "exceptionalHazard",
    otAdded = "otAdded",
    otCanceled = "otCanceled",
    deliveryRefused = "deliveryRefused",
    requiredAction = "requiredAction",
    hazardDeclared = "hazardDeclared",
}

export enum BusinessNotificationType {
    declineDelivery = "declineDelivery",
    addCommentByShippingManager = "addCommentByShippingManager",
    updateCommentByShippingManager = "updateCommentByShippingManager",
    deleteCommentByShippingManager = "deleteCommentByShippingManager",
    addCommentByOperator = "addCommentByOperator",
    updateCommentByOperator = "updateCommentByOperator",
    deleteCommentByOperator = "deleteCommentByOperator",
    forcedTrailerPermission = "forcedTrailerPermission",
    forcedPlatformPermissionRequest = "forcedPlatformPermissionRequest",
    forcedPlatformPermissionResponse = "forcedPlatformPermissionResponse",
    cancelTourOrder = "cancelTourOrder",
    tourOrderAdded = "tourOrderAdded",
    cancelDelivery = "cancelDelivery",
    changePlatform = "changePlatform",
    updateTourOrder = "updateTourOrder",
    driverHazardTaskRegister = "driverHazardTaskRegister",
}

export interface Notification {
    id: ObjectId;
    type: "text";
    channel: "pull";
    payload: {
        text: { [key in SupportedLanguage]: string };
        title: { [key in SupportedLanguage]: string };
        custom: {
            businessNotificationType: BusinessNotificationType;
            [key: string]: any;
        };
    };
    reference: string;
    status: NotificationStatus;
    sendAt: string;
}

export enum ValueListFieldValueType {
    weekday = "weekday",
    string = "string",
    number = "number",
    boolean = "boolean",
    enum = "enum",
    phoneNumber = "phoneNumber",
    email = "email",
}

export enum ValueListFieldUnit {
    second = "second",
    minute = "minute",
    hour = "hour",
    day = "day",
    week = "week",
    month = "month",
    year = "year",
}

export interface ValueListField {
    id: string;
    title: string;
    columnTitle?: string;
    isSortable?: boolean;
    sortKey?: string;
    name: string;
    valueType: ValueListFieldValueType;
    unit?: ValueListFieldUnit;
    minValue?: number;
    maxValue?: number;
    isEnabled: boolean;
    isRequired?: boolean;
    enum?: string[];
}
export interface ValueList {
    id: string;
    title: string;
    slug: ValueListSlug;
    fields: ValueListField[];
    meta: {
        [key: string]: any;
    };
}

export enum ValueListSlug {
    activities = "activities",
    delays = "delays",
    driverHazards = "driverHazards",
    operatingHazards = "operatingHazards",
    palletSupports = "palletSupports",
    platforms = "platforms",
    products = "products",
    reasonsForNonCompliance = "reasonsForNonCompliance",
    reasonsRefusingTruck = "reasonsRefusingTruck",
    trailerDropZones = "trailerDropZones",
    trailerTypes = "trailerTypes",
    warehouses = "warehouses",
}

export interface ValueListItem<TFields = Record<string, string | number>, TMeta = Record<string, any>> {
    id: string;
    title: string;
    fields: TFields;
    meta: TMeta;
}

export enum AgendaJobStatus {
    finished = "finished",
    failed = "failed",
    scheduled = "scheduled",
    running = "running",
}
export interface AgendaJob {
    id: ObjectId;
    name: string;
    type: string;
    priority: number;
    failCount?: number;
    failReason?: string;
    failedAt?: string;
    nextRunAt?: string;
    lastModifiedBy?: string | null;
    lockedAt?: string | null;
    lastRunAt?: string | null;
    lastFinishedAt?: string;
    status?: AgendaJobStatus;
    data: {
        [key: string]: unknown;
    };
}
export interface RemoteFile {
    id: string;
    originalName: string;
    mimeType: string;
    url: string;
    thumbnailUrl?: string;
    name?: string;
}

export interface Store {
    id: ObjectId;
    organization: Organization;
    reference: string;
    sign?: string;
    name: string;
    address: Address;
    numberOfDocks: number;
    qrCode: string;
    type: StoreType;
    secondaryAddress?: Address;
    secondaryNumberOfDocks?: number;
    secondaryQrCode?: string;
    computedProperties?: {
        contacts: Array<{
            fullName: string;
            phone: string;
            email: string;
        }>;
    };
    contacts?: User[];
    createdAt: string;
    updatedAt: string;
}

export enum CarrierAttachment {
    carrier = "carrier",
    scapnor = "scapnor",
}

export enum StoreType {
    drive = "drive",
    express = "express",
    hyper = "hyper",
    super = "super",
}

export interface Carrier {
    id: string;
    organization: Organization;
    reference: string;
    name: string;
    phone: string;
    address: Address;
    attachment: CarrierAttachment;
    createdAt: string;
    updatedAt: string;
    drivers?: User[];
}

export enum TrailerContract {
    permanent = "permanent",
    temporary = "temporary",
    tourOrder = "tourOrder",
}

export enum TrailerStatus {
    allocated = "allocated",
    loading = "loading",
    loaded = "loaded",
    onDelivery = "onDelivery",
    inReturnWithSupports = "inReturnWithSupports",
    overhanging = "overhanging",
    inactive = "inactive",
    inReturnWithoutSupports = "inReturnWithoutSupports",
    available = "available",
}

export interface Trailer {
    id: ObjectId;
    organization: Organization;
    reference: string;
    registration: string;
    contract: TrailerContract;
    type?: ValueListItem;
    location?: ValueListItem;
    status: TrailerStatus;
    currentTourOrder?: TourOrder;
    scheduledReturn?: string;
    createdInTourOrder?: boolean;
    computedProperties: {
        organizationSlug?: string;
        organizationName?: string;
        tourOrderReference?: string;
        locationZone?: string;
        warehouseName?: string;
        platformName?: string;
        type?: string;
    };
    createdAt: string;
    updatedAt: string;
}

export enum TourOrderStatus {
    readyToStart = "readyToStart",
    loading = "loading",
    virtuallyLoaded = "virtuallyLoaded",
    loaded = "loaded",
    onDelivery = "onDelivery",
    onReturn = "onReturn",
    onReturnWithSupports = "onReturnWithSupports",
    unloadingSupports = "unloadingSupports",
    supportsUnloaded = "supportsUnloaded",
    overhanging = "overhanging",
    completed = "completed",
    completedWithSupportsUnloaded = "completedWithSupportsUnloaded",
    canceled = "canceled",
    postponed = "postponed",
}

export enum TourOrderDriverType {
    substitute = "substitute",
    holder = "holder",
}

export enum TaskType {
    startTourOrder = "startTourOrder",
    trailerPickup = "trailerPickup",
    loading = "loading",
    delivery = "delivery",
    trailerDepot = "trailerDepot",
    hazard = "hazard",
    break = "break",
}

export interface CommonTaskFields {
    id: ObjectId;
    type: TaskType;
    initialStartTime: string;
    initialEndTime?: string;
    startTime: string;
    endTime?: string;
    startedTime?: string;
    endedTime?: string;
    index: number;
    address?: Address;
    delay?: number;
}

export enum TrailerPickupTaskEvent {
    trailerPickedUp = "trailerPickedUp",
}

export interface TrailerPickupTask extends CommonTaskFields {
    trailer?: Trailer;
    location?: ValueListItem;
    warehouse?: ValueListItem;
    platform?: ValueListItem;
    forcedTrailerPermission?: ForcedTrailerPermission;
    status: TrailerPickupTaskStatus;
    events?: {
        date: Date;
        name: TrailerPickupTaskEvent;
    }[];
    hide?: boolean;
}

export interface LoadingItem {
    store: Store;
    pallets: {
        product: ValueListItem;
        quantity: number;
        orders: string[];
    }[];
}

export enum TrailerPickupTaskStatus {
    todo = "todo",
    preloaded = "preloaded",
    completed = "completed",
    canceled = "canceled",
}

export enum LoadingTaskStatus {
    todo = "todo",
    loading = "loading",
    preloaded = "preloaded",
    loaded = "loaded",
    canceled = "canceled",
}

export enum DeliveryTaskStatus {
    todo = "todo",
    onTheWay = "onTheWay",
    arrived = "arrived",
    onDelivery = "onDelivery",
    preloaded = "preloaded",
    delivered = "delivered",
    declined = "declined",
    canceled = "canceled",
}

export enum TrailerDepotTaskStatus {
    todo = "todo",
    onTheWay = "onTheWay",
    inProgress = "inProgress",
    readyToUnload = "readyToUnload",
    unloadingSupport = "unloadingSupport",
    completed = "completed",
    canceled = "canceled",
}

export enum BreakTaskStatus {
    todo = "todo",
    completed = "completed",
}

export enum StartTourOrderTaskStatus {
    completed = "completed",
}

export enum LoadingTaskEvent {
    arrivedAtThePlatform = "arrivedAtThePlatform",
    loadingStarted = "loadingStarted",
    loadingEnded = "loadingEnded",
    departureFromPlatform = "departureFromPlatform",
    confirmDeliveryNote = "confirmDeliveryNote",
    departureFromWarehouses = "departureFromWarehouses",
}

export enum DeliveryTaskEvent {
    arrivedAtStore = "arrivedAtStore",
    declinedDelivery = "declinedDelivery",
    deliveryStarted = "deliveryStarted",
    delivered = "delivered",
    departureFromStore = "departureFromStore",
}

export enum HazardTaskStatus {
    completed = "completed",
}

export enum HazardTaskType {
    driverHazard = "driverHazard",
    operatingHazard = "operatingHazard",
}

export interface Comment {
    comment: string;
    date: Date;
}

export interface LoadingTask extends CommonTaskFields {
    warehouse?: ValueListItem;
    platform?: ValueListItem;
    forcedPlatformPermission?: ForcedPlatformPermission;
    yardDriver?: User;
    numberOfPalletsToLoad: number;
    numberOfPalletsLoaded?: number;
    loadingItems: LoadingItem[];
    status: LoadingTaskStatus;
    address: Address;
    loader?: string;
    shippingServiceComment?: Comment;
    hazards?: HazardTask[];
    breakTasks?: BreakTask[];
    events?: {
        date: string;
        name: LoadingTaskEvent;
    }[];
    simulated?: boolean;
}

export interface SupportToDeliver {
    support: ValueListItem;
    quantity: number;
    deliveredQuantity?: number;
}

export interface ReturnedSupport {
    support: ValueListItem;
    quantity: number;
    returnedQuantity?: number;
}

export interface NonComplianceImage {
    original: RemoteFile;
    smallSize: RemoteFile;
}

export interface NonCompliance {
    reason: ValueListItem;
    scan?: string;
    images?: NonComplianceImage[];
}

export interface DeliveryTask extends CommonTaskFields {
    store: Store;
    numberOfPallets: number;
    status: DeliveryTaskStatus;
    supportsToDeliver?: SupportToDeliver[];
    returnedSupports?: ReturnedSupport[];
    nonCompliances?: NonCompliance[];
    route?: Route;
    events?: {
        date: string;
        name: DeliveryTaskEvent;
    }[];
    declinedDetail?: {
        reason?: ValueListItem;
        comment?: string;
    };
    signature?: RemoteFile;
    hazards?: HazardTask[];
    breakTasks?: BreakTask[];
    isLast?: boolean;
}

export enum DepotType {
    scapnor = "scapnor",
    partner = "partner",
}

export interface TrailerDepotTask extends CommonTaskFields {
    status: TrailerDepotTaskStatus;
    depotType: DepotType;
    location?: ValueListItem;
    warehouse?: ValueListItem;
    platform?: ValueListItem;
    isSupportReturned: boolean;
    forcedPlatformPermission?: ForcedPlatformPermission;
    forcedDepotZonePermission?: {
        accepted?: boolean;
        date: string;
    };
    route?: Route;
    tourOrder?: TourOrder;
}

export interface BreakTask extends CommonTaskFields {
    status: BreakTaskStatus;
}

export interface StartTourOrderTask extends CommonTaskFields {
    status: StartTourOrderTaskStatus;
}

export interface ValueListItemHazard {
    excludedFromPunctualityRate: boolean;
    hazard: string;
    recalculation: boolean;
}

export interface HazardTask extends CommonTaskFields {
    status?: HazardTaskStatus;
    reason: ValueListItem<ValueListItemHazard>;
    hazardTaskType: HazardTaskType;
    comment?: string;
}

export interface BreakTask extends CommonTaskFields {
    minutes: number;
}

export type Task =
    | TrailerPickupTask
    | LoadingTask
    | DeliveryTask
    | TrailerDepotTask
    | BreakTask
    | StartTourOrderTask
    | HazardTask
    | BreakTask;

export interface TourOrder {
    id: ObjectId;
    organization: Organization;
    parentReference: string;
    reference: string;
    date: string;
    scheduledEndTime: string;
    status: TourOrderStatus;
    driver?: User;
    driverType: TourOrderDriverType;
    scheduledDeparture: string;
    actualDeparture?: string;
    tasks: Task[];
    activities: ValueListItem[];
    carrier?: Carrier;
    operationComment?: Comment;
    expectedDistance: number;
    traveledDistance: number;
    truckRegistration?: string;
    truckMileage?: number;
    delay: number;
    createdBy?: User;
    degradedModeUpdatedBy?: User;
    computedProperties: {
        organizationSlug?: string;
        organizationName?: string;
        fresh?: boolean;
        warning?: boolean;
        driverFirstName?: string;
        driverLastName?: string;
        stores?: {
            name?: Store["name"];
            reference?: Store["reference"];
        }[];
        numberOfPallets?: number;
        activityReferences?: string[];
        platformNumbers?: number[];
        trailerReference?: string;
        trailerRegistration?: string;
        trailerCreatedInTourOrder?: boolean;
        carrierName?: string;
        yardDrivers?: {
            firstName?: string;
            lastName?: string;
            taskCompleted?: boolean;
            warehouseName?: string;
        }[];
        lastPosition?: {
            point: GeoJSON.Point;
            heading: number;
            date: string;
        };
        isSupportReturned?: boolean;
        totalReturnedSupports?: number;
        returnedSupports?: TourOrderReturnedSupport[];
        returnLocation?: string;
    };
    createdAt: string;
    updatedAt: string;
}

export interface TourOrderReturnedSupport {
    support: string;
    stores: {
        reference: string;
        quantity: number;
        returnedQuantity: number;
    }[];
}

export enum TourOrderColumnKey {
    reference = "reference",
    date = "date",
    status = "status",
    driver = "driver",
    startTime = "startTime",
    scheduledDeparture = "scheduledDeparture",
    actualDeparture = "actualDeparture",
    tasks = "tasks",
    stores = "stores",
    pallets = "pallets",
    activities = "activities",
    platforms = "platforms",
    trailer = "trailer",
    carrier = "carrier",
    yardDrivers = "yardDrivers",
    comments = "comments",
}

export enum StorePortalTourOrderColumnKey {
    reference = "reference",
    date = "date",
    status = "status",
    scheduledDeparture = "scheduledDeparture",
    actualDeparture = "actualDeparture",
    startTime = "startTime",
    pallets = "pallets",
    activities = "activities",
    driver = "driver",
    carrier = "carrier",
}

export enum RouteAnomalyColumnKey {
    reference = "reference",
    date = "date",
    status = "status",
    driver = "driver",
    startTime = "startTime",
    scheduledDeparture = "scheduledDeparture",
    actualDeparture = "actualDeparture",
    tasks = "tasks",
    stores = "stores",
}

export enum ReturnedSupportColumnKey {
    reference = "reference",
    date = "date",
    status = "status",
    returnLocation = "returnLocation",
    initialStartTime = "initialStartTime",
    startedTime = "startedTime",
    driver = "driver",
    trailer = "trailer",
    stores = "stores",
    totalReturnedSupports = "totalReturnedSupports",
    returnedSupports = "returnedSupports",
    truckRegistration = "truckRegistration",
    carrier = "carrier",
}

export enum ShippingManagementColumnKey {
    reference = "reference",
    date = "date",
    stores = "stores",
    platform = "platform",
    startTime = "startTime",
    status = "status",
    startedTime = "startedTime",
    endTime = "endTime",
    endedTime = "endedTime",
    numberOfPalletsToLoad = "numberOfPalletsToLoad",
    loadingToBeFollowed = "loadingToBeFollowed",
    loader = "loader",
    trailer = "trailer",
    comments = "comments",
}

export enum TableTemplateKey {
    tourOrdersControlRole = "tourOrdersControlRole",
    loading = "loading",
    supportReturn = "supportReturn",
    driverTracking = "driverTracking",
    tourOrdersForDriverTracking = "tourOrdersForDriverTracking",
    tourOrdersAnomaly = "tourOrdersAnomaly",
    tourOrdersStore = "tourOrdersStore",
}

export interface TableTemplateItem {
    key: string;
    sortKey?: string;
    index: number;
}

export interface TableTemplate {
    id: ObjectId;
    key: TableTemplateKey | string;
    columns?: TableTemplateItem[];
    items?: TableTemplateItem[];
    user: User;
    createdAt: string;
    updatedAt: string;
}

export interface Loading {
    id: ObjectId;
    tourOrder: TourOrder;
    date: string;
    loadingToBeFollowed: number;
    activities: ValueListItem[];
    trailerPickupTask: TrailerPickupTask;
    task: LoadingTask;
    computedProperties: {
        tourOrderReference: TourOrder["reference"];
        fresh?: boolean;
        warning?: boolean;
        stores?: {
            reference?: Store["reference"];
            name?: Store["name"];
        }[];
        activityReferences?: string[];
        trailerReference?: string;
        trailerRegistration?: string;
        selectedPlatform?: string;
        operationComment?: Comment;
    };
    createdAt: string;
    updatedAt: string;
}

export interface OrtecRouteError {
    id: ObjectId;
    body: ObjectId;
    errorMessage: string;
    createdAt: string;
    updatedAt: string;
}

export interface ZodErrorDetail {
    code: string;
    expected: string;
    received: string;
    path: string[];
    message: string;
}

export type ZodError = ZodErrorDetail[];

export interface Route {
    id: ObjectId;
    organization: ObjectId;
    tourOrder: ObjectId;
    start: Address;
    end: Address;
    distance: number;
    duration: number;
    coordinates: number[][];
    computedProperties: {
        organizationSlug?: string;
        organizationName?: string;
        tourOrderReference?: string;
    };
    createdAt: string;
    updatedAt: string;
}

export interface ForcedPlatformPermission {
    platform: ValueListItem;
    accepted?: boolean;
    date: string;
}

export interface ForcedTrailerPermission {
    trailer: Trailer;
    accepted?: boolean;
    date: string;
}

export interface Track {
    id: ObjectId;
    tourOrder: TourOrder;
    driver: User;
    coordinates: number[];
    heading: number;
    timestamp: string;
    createdAt: string;
    updatedAt: string;
}

export interface Trace {
    id: ObjectId;
    tourOrder: TourOrder;
    lineStrings: GeoJSON.LineString[];
    originalPoints?: GeoJSON.Point[];
    heading: number;
    lastTimestamp: string;
    createdAt: string;
    updatedAt: string;
}

export type MetricsWithData<T> = T & {
    // Populated data
    _data?: unknown;
};
export type EmbeddedMetrics<T> = {
    [facet: string]: EmbeddedMetrics<T> | T | MetricsWithData<T>;
    _total: T;
};
export type MetricsResponse<T> = T | EmbeddedMetrics<T>;

export type MetricFacet = "year" | "month" | "date" | "activity";
