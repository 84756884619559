import {
    BreakTask,
    DeliveryTask,
    HazardTask,
    LoadingTask,
    StartTourOrderTask,
    Task,
    TaskType,
    TrailerDepotTask,
    TrailerPickupTask,
} from "../queries/api/types";

export function isTourOrderTask(task: Task): task is Task {
    return Object.values(TaskType).includes(task.type);
}

export function isTrailerPickupTask(task: Task): task is TrailerPickupTask {
    return task.type === TaskType.trailerPickup;
}

export function isLoadingTask(task: Task): task is LoadingTask {
    return task.type === TaskType.loading;
}

export function isDeliveryTask(task: Task): task is DeliveryTask {
    return task.type === TaskType.delivery;
}

export function isTrailerDepotTask(task: Task): task is TrailerDepotTask {
    return task.type === TaskType.trailerDepot;
}

export function isBreakTask(task: Task): task is BreakTask {
    return task.type === TaskType.break;
}

export function isStartTourOrderTask(task: Task): task is StartTourOrderTask {
    return task.type === TaskType.startTourOrder;
}

export function isHazardTask(task: Task): task is HazardTask {
    return task.type === TaskType.hazard;
}
