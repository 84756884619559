import QrCreator from "qr-creator";
import { Store } from "antd/lib/form/interface";

import { downloadFile } from ".";
import { zipAndDownload } from "./zip";
import { RoleSlug, User } from "../queries/api/types";
import { hasRole } from "./security";

interface QRCodeData {
    text: string;
    filename: string;
}

export const downloadQrCodes = async (data: QRCodeData[] | QRCodeData, zipFilename?: string) => {
    const isArray = Array.isArray(data);

    if (isArray) {
        if (!data.length) {
            return;
        }

        const filesBlob = await Promise.allSettled(data.map(({ text }) => createQrCodeAsBlob(text)));

        const filesData = filesBlob
            .map((result, index) => {
                if (result.status === "fulfilled" && result.value !== null) {
                    return {
                        filename: `${data[index].filename}.png`,
                        file: result.value,
                    };
                } else {
                    return false;
                }
            })
            .filter(Boolean);

        zipAndDownload(filesData, zipFilename);
    } else {
        const blob = await createQrCodeAsBlob(data.text);

        if (blob) {
            const fileURL = URL.createObjectURL(blob);
            downloadFile(fileURL, data.filename);
            URL.revokeObjectURL(fileURL);
        }
    }
};

export const createQrCodeAsBlob = (data: string) => {
    const canvas = document.createElement("canvas");

    QrCreator.render(
        {
            text: data,
            radius: 0.1, // 0.0 to 0.5
            ecLevel: "H", // L, M, Q, H
            fill: "#0066cc", // foreground color
            background: null, // color or null for transparent
            size: 512, // in pixels
        },
        canvas,
    );

    return new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve);
    });
};

export const downloadUserQRcode = async (user: User) => {
    const { token, lastName, firstName, username } = user;
    const isDriver = hasRole(user, [RoleSlug.driver]);
    if (!token) {
        throw new Error("No token found");
    }
    const driverQRCode = {
        text: token,
        filename: isDriver ? `${lastName}_${firstName}_${username}_qr_code` : `${lastName}_${firstName}_qr_code`,
    };

    downloadQrCodes(driverQRCode);
};

export const downloadStoreQRcodes = async (store: Store) => {
    const primaryQRCode = {
        text: store.qrCode,
        filename: `qrcode-magasin-${store.reference}`,
    };
    const qrCodes = store.secondaryQrCode
        ? [
              primaryQRCode,
              {
                  text: store.secondaryQrCode,
                  filename: `qrcode-magasin-${store.reference}-secondaire`,
              },
          ]
        : primaryQRCode;

    downloadQrCodes(qrCodes, store.secondaryQrCode ? `qrcodes-magasin-${store.reference}` : undefined);
};
